<script>
	import Navigation from '../../sections/Navigation.svelte';
	import Modal from '../../components/Modal.svelte';
	import { graphCategories, homeContent, sources } from '$lib/graphLists.js';
	import { EthiClaims, UserInfo, Version, Path, IsElectron, IsDev } from '$lib/store.js';
	import { getUserInfo } from '$lib/utils.js';
	import { goto } from '$app/navigation';
	import { fade } from 'svelte/transition';
	import { onMount } from 'svelte';
	onMount(() => {
		if (window.innerWidth < 450) goto('/ai');
	});
	// if ($IsElectron) {
	// 	window.api?.call('sessionFullSize');
	// }
	let local;
	let text = '';
	let hasBeenUsed;
	$: hasBeenUsed = hasBeenUsed || text.length > 0;
	let hasDoneCompletion, hasUsedAudioPaste, hasUsedCommandPallette;
	let showLocalOption = $IsDev;
	let localModel = '';
	let openAIKey = '';
</script>

<div class="invisilink">
	{#each ['/', '/data/', '/ai/', '/sub/', '/done/', '/version', '/session/', '/social/', '/time/', '/whoop/', '/register/', '/connected/', '/upload/', '/login/', '/action/', '/connect/', '/logout/', '/profile/', '/profile/data-choices/', '/profile/plans/', '/profile/account/', '/profile/referrals/', '/onboard/', '/onboard/sync/', '/onboard/demo/', '/demo', '/onboard/intro/', '/onboard/privacy/', '/onboard/call/', '/onboard/insights/', '/onboard/track/', '/onboard/apple/', '/onboard/spotify/', '/onboard/twitter/', '/onboard/intro/', '/onboard/intro/understand/', '/onboard/intro/control/', '/onboard/intro/use/', '/onboard/download/', '/onboard/connect/', '/onboard/subscribe/', '/onboard/complete/', '/subscribed/'] as link}
		<a class="invisilink" href={link}>.</a>
		<!-- <a class="invisilink" href={link.slice(-1)}>.</a> -->
	{/each}

	<!-- {#each sources as source}
		{#if source}
			<a class="invisilink" href="understand/{source}/">.</a>
			{#each graphCategories as category}
				<a class="invisilink" href="understand/{source}/{category}/">{category}</a>
				{#each homeContent[category].graphList as graph}
					<a class="invisilink" href="understand/{source}/{graph.graphLink}/"> . </a>
				{/each}
			{/each}
		{/if}
	{/each} -->
</div>
{#if $Version?.app == 'omnipilot'}
	<Navigation />
	<div class="fixed centreInContext flex flex-col text-lg items-stretch gap-4">
		<h1 class="text-3xl font-semibold">Omnipilot is now running, go try it out!</h1>
		<div class="text-sm opacity-60 hover:opacity-100">
			Have you completed the Omnipilot onboarding? If not, <a
				on:click|preventDefault={() => {
					window.wsend?.({ name: 'omnipilotOnboarding', to: ['ai'] });
					window.api?.call('chatShow');
				}}
				href="/onboard/track"><button>Click here</button></a
			>
		</div>
		<!-- {#if $IsElectron}
			<button
				class="w-full"
				on:click={(event) => {
					window?.api?.call('showSessionCoach');
				}}
			>
				Show session coach
			</button>
		{/if} -->
		<!-- <div class="rounded-2xl -mx-2 p-5 pb-3 pt-4 mt-4  bg-gray-900 border border-gray-800">
			<div class="mb-2 text-lg font-medium">
				Type a few words into the text-box below and wait for Omnipilot to suggest some text. Press <code
					class="font-bold">Tab</code
				> to accept.
			</div>
			<div class="mt-2">
				<textarea
					placeholder="Type a few words here to test omnipilot"
					bind:value={text}
					on:load={() => setTimeout(() => this.focus(), 1000)}
					class="w-full border border-gray-800  rounded-md p-2"
					style="min-height:6rem;"
					type="text"
				/>
			</div>
		</div>

		{#if hasBeenUsed}
			<div in:fade={{ delay: 6000, duration: 1000 }} class="mb-2 ">
				Omnipilot works in most text-boxes on your computer. If you find somewhere it doesn't
				activate, or where you wish it wouldn't, let me know with the Help command!
			</div>
			<div in:fade={{ delay: 8000, duration: 1000 }} class="my-2  font-medium">
				Press <span class="font-bold">Opt-.</span> to use "Ghostwrite", to verbally dictate to GPT-4
				what you want it write.
			</div>
			<div in:fade={{ delay: 10000, duration: 1000 }}>
				Finally, to dismiss this onboarding and open the Omnipilot command pallette and integrated
				AI assistant, press:<br /><span class="font-bold"><span
					class="inline-block bg-slate-700 font-semibold mx-0.5 text-base color-gray-400 opacity-80 bg-opacity-60 px-2 py-1 rounded-md"
				>Option</span> <span
					class="inline-block bg-slate-700 font-semibold mx-0.5 text-base color-gray-400 opacity-80 bg-opacity-60 px-2 py-1 rounded-md"
				>Space</span></span>
			</div>
			<p in:fade={{ delay: 12000, duration: 1000 }}>
				The only Omnipilot context that leaves your computer is the call that goes to OpenAI, which
				isn't saved or cached anywhere.
			</p>
			{#if showLocalOption}
				<button in:fade={{ delay: 12000, duration: 1000 }} on:click={() => (local = !local)}
					>Interested in local models?</button
				>
			{/if}
		{:else} -->
		<div in:fade={{ delay: 1000, duration: 1000 }} class="mb-2">
			Omnipilot works in most text-boxes on your computer. If you find somewhere it doesn't
			activate, or where you wish it wouldn't, let me know with the Help button on the top right!
		</div>
		<div in:fade={{ delay: 1000, duration: 1000 }} class="my-2 font-medium">
			Press <span class="font-bold"
				><span
					class="inline-block bg-slate-700 font-semibold mx-0.5 text-base color-gray-400 opacity-80 bg-opacity-60 px-2 py-1 rounded-md"
					>Option</span
				>
				<span
					class="inline-block bg-slate-700 font-semibold mx-0.5 text-base color-gray-400 opacity-80 bg-opacity-60 px-2 py-1 rounded-md"
					>/</span
				></span
			> to use "Smart Compose", to tell GPT-4 what you want it write in any text box.
		</div>
		<div in:fade={{ delay: 2000, duration: 1000 }}>
			Finally, to dismiss this onboarding and open the Omnipilot command pallette and integrated AI
			assistant, press:<br /><span class="font-bold"
				><span
					class="inline-block bg-slate-700 font-semibold mx-0.5 text-base color-gray-400 opacity-80 bg-opacity-60 px-2 py-1 rounded-md"
					>Option</span
				>
				<span
					class="inline-block bg-slate-700 font-semibold mx-0.5 text-base color-gray-400 opacity-80 bg-opacity-60 px-2 py-1 rounded-md"
					>Space</span
				></span
			>
		</div>
		{#if showLocalOption}
			<div>
				<button in:fade={{ delay: 2000, duration: 1000 }} on:click={() => (local = !local)}
					>Want to use your OpenAI key or local models?</button
				>
				{#if $IsDev}
					<button on:click={() => window.api?.call('chatShow')}>show chat</button>
					<button on:click={() => window.api?.call('showDock')}>show dock</button>
					<button on:click={() => goto('/onboard/demo')}>goto demo</button>
				{/if}
			</div>
		{/if}
		<!-- {/if} -->
		<Modal bind:show={local} options={{ topLevel: true }}>
			<div>
				<h2>Bring your own tokens</h2>
				<p>
					Omnipilot very private by default, as data is sent encrypted over an HTTPS connection
					between this app and OpenAI's server via a thin wrapper that only adds Omnipilot's
					credentials and logs no data.
				</p>
				<p>
					However, if you'd much prefer a local model that doesn't require an internet connection,
					or to use your own OpenAI Key, we'd love to support that.
				</p>
				<p>
					Set up the <a href="https://github.com/ggerganov/llama.cpp">Llama.cpp</a> server on your computer,
					or an equivalent server, and Omnipilot can use that to generate text instead.
				</p>
				<p>
					It'll be less accurate and slower, but it'll be fully private, and you can use it offline.
				</p>
				<div class="flex justify-between">
					<input
						bind:value={localModel}
						placeholder="base URL of Llama.cpp server, e.g. http://localhost:8080"
					/>
					<button disabled={!localModel}>Switch to using local model</button>
				</div>
				<div class="flex justify-between">
					<input
						bind:value={openAIKey}
						placeholder="your OpenAI key, e.g. sk-fo35u3ah235ao87y34t2..."
					/>
					<button disabled={!openAIKey}>Use your key</button>
				</div>
			</div>
		</Modal>
	</div>
{/if}

<style>
	.invisilink {
		visibility: hidden;
		position: absolute;
		top: -1000px;
	}
	p {
		margin-bottom: 8px;
	}
</style>
